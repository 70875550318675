"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Index',
  data: function data() {
    return {
      user: ''
    };
  },
  methods: {
    go: function go(type) {
      this.$router.push({
        path: '/user/identity-verify',
        query: {
          type: type
        }
      });
    }
  },
  created: function created() {
    this.user = this.userInfo();
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Wechat',
  data: function data() {
    return {};
  },
  computed: {
    wechatBindInfo: function wechatBindInfo() {
      var user = this.userInfo();

      if (user.wechat) {
        return user.wechat.data.nickname;
      } else {
        return '未关联';
      }
    }
  },
  methods: {
    click: function click() {
      var bind = this.userInfo().wechat;

      if (bind) {
        this.$router.push({
          name: 'bindWechat'
        });
      } else if (!bind && !this.isWeixinBrowser()) {
        this.$toast('请在微信里绑定');
      } else {
        this.$router.push(this.getWxAuthorizeUrl(this.$route.path));
      }
    }
  },
  created: function created() {
    if (this.$route.query.uid > 0) {
      this.$toast.success('绑定成功');
    }
  }
};
exports.default = _default;
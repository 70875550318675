"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Wechat',
  data: function data() {
    return {
      disabled: false,
      user: this.userInfo()
    };
  },
  methods: {
    confirm: function confirm() {
      var _this = this;

      var that = this;
      this.$dialog.confirm({
        title: '确认',
        message: "\u786E\u8BA4\u4E0E\u5FAE\u4FE1 ".concat(this.user.wechat.data.nickname, " \u89E3\u7ED1\u5417?")
      }).then(function () {
        _this.$store.dispatch('user/disbindwechat').then(function (res) {
          _this.$toast.success({
            'message': '解绑成功',
            onClose: function onClose() {
              that.$router.push({
                name: 'bindIndex'
              });
            }
          });
        });
      }).catch(function () {// on cancel
      });
    }
  }
};
exports.default = _default;
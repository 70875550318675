"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("@/api/user/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Modpwd',
  watch: {
    password: function password() {
      this.check();
    },
    repassword: function repassword() {
      this.check();
    }
  },
  data: function data() {
    return {
      passwordType: 'password',
      password: '',
      repassword: '',
      disabled: true
    };
  },
  methods: {
    switchPasswordType: function switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },
    onSubmit: function onSubmit(values) {
      var _this = this;

      console.log('submit', values);
      var that = this;
      (0, _index.changePassword)(values).then(function (res) {
        if (res.code === 0) {
          _this.$toast.success({
            'message': '设置成功',
            onClose: function onClose() {
              that.$store.dispatch('user/getInfo').then(function () {
                that.$router.push({
                  name: 'secure'
                });
              });
            }
          });
        }
      });
    },
    check: function check() {
      var disabled = true;

      if (this.password.length >= 6 && this.repassword === this.password) {
        disabled = false;
      }

      this.disabled = disabled;
    }
  },
  created: function created() {}
};
exports.default = _default;